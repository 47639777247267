import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoes of Opera</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_opera.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Echoes of Opera</h1>
          <h2>Everything you need to know about the Echoes of Opera Raid!</h2>
          <p>
            Last updated: <strong>19/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <StaticImage
        src="../../../images/reverse/generic/boss_7.webp"
        alt="Raid Boss"
      />
      <p>
        A returning boss from our favorite doomed yuri chapter (1.7), the Echoes
        of Opera twirls its way into Mane’s Bulletin to be matched with any team
        that has that special certain someone that does all the work for them.
        Pick your main character, mind the dazzling spotlights, and prepare for
        a grand show..
      </p>
      <p>There are a total of 3 types of enemies in this fight:</p>
      <ul>
        <li>The Misplaced Artist who appears as the enormous burning ghost.</li>
        <li>The blue shade, known as the Stand-In Actor.</li>
        <li>Finally, the red spirit, called the Impromptu Spectator.</li>
      </ul>
      <SectionHeader title="Field Hazards [Endless Mode]" />
      <ul>
        <li>
          After each action, allies gain DMG Bonus and Leech Rate for that
          round.
        </li>
        <li>All allies (regardless of Afflatus) have increased stats.</li>
        <li>
          At the start of each round, enemies gain a layer of [Growth] that
          increases their DMG Dealt. Whenever an enemy dies, they dispel all
          stacks of this from their other allies.
        </li>
      </ul>
      <SectionHeader title="Boss Mechanics [Endless Mode]" />
      <h5>Misplaced Artist</h5>
      <p>
        Note: This boss has SS Reality DEF and S Mental DEF, meaning that Mental
        Carry characters are favored in this raid.
      </p>
      <ul>
        <li>
          <strong>Special Seat</strong> - Upon entering combat, declares the
          leftmost Arcanist the [Special Guest] and grants them 10 stacks of
          [Heightened Standards]. Damage taken from Arcanists without [Special
          Guest] is reduced by 30%.
        </li>
        <ul>
          <li>
            → [Special Guest]: Gain massively increased stats when attacking
            Misplaced Artist. At the start of the round, generate a random
            precast Incantation of the carrier.
          </li>
          <li>
            → [Heightened Standards]: If the carrier is holding 5+ stacks, they
            do not gain any stat bonuses when attacking Misplaced Artist. If
            holding 9+ (max 10) stacks, cannot act (this bypasses [Immunity]).
          </li>
          <ul>
            <li>
              The idea here is that you designate 1 character to be your Carry
              for the fight, and essentially play around lowering their stacks
              of [Heightened Standards] to allow them to deal actual damage.
            </li>
          </ul>
        </ul>
        <li>
          <strong>Voice Within</strong> - When enemies take lethal damage,
          dispel 2 stacks of [Heightened Standards] from the [Special Guest].
          When the [Special Guest] is targeted by a Heal or Buff Incantation,
          dispel 1 stack. If the Incantation is a 1-target Incantation, dispel
          an additional stack.
        </li>
        <ul>
          <li>
            The way you’re going to remove stacks of that pesky [Heightened
            Standards] (which is really sad if you think about it from the story
            context). Essentially, this favors Supports who have single-target
            Incantations such as Vila, Yenisei and 6 while still allowing other
            Supports to be used.
          </li>
        </ul>
        <li>
          <strong>Unforgettable Performance</strong> - At the end of the round,
          deals Mental DMG to all enemies and additional Genesis DMG to the
          [Special Guest]. If attacked by the [Special Guest] this round, it
          also inflicts 4 stacks of [Heightened Standards].
        </li>
        <ul>
          <li>
            Essentially the reason why people run characters like Windsong,
            Kaalaa Baunaa or other characters who prefer their damage output in
            fit bursts rather than consistent over-time application.
          </li>
        </ul>
        <li>
          <strong>Control Immunity</strong> - As is standard with the main body
          of a boss, the Misplaced Artist is immune to [Control] effects.
        </li>
        <li>
          Like the other Raid Bosses, when maximum [Eureka] is reached, the boss
          enters a [Shield] phase for 2 rounds before casting its Ultimate. If
          the [Shield] is broken, they are knocked out for a round and lose all
          their [Eureka].
        </li>
        <li>
          [Buff] Card - Self-buff. Gains a shield for 1 round that reduces DMG
          Taken by 50% while its active.
        </li>
        <li>
          [Debuff] Card - Mass debuff. Inflicts DMG Taken +50% for 1 round.
        </li>
      </ul>
      <h5>Stand-In Actor</h5>
      <ul>
        <li>
          <strong>Roaming Shade</strong> - When taking lethal damage, regain
          100% Max HP and enter [Daze] for 1 round.
        </li>
        <li>[Attack] Card - Deals 1-target Mental DMG</li>
      </ul>
      <h5>Impromptu Spectator</h5>
      <ul>
        <li>
          <strong>Roaming Shade</strong> - When taking lethal damage, regain
          100% Max HP and enter [Daze] for 1 round.
        </li>
        <li>[Attack] Card - Deals Mental DMG to 2 targets.</li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        Mental DMG carries shine in this fight, especially those with access to
        high Burst DMG such as Windsong, Kaalaa Baunaa and Marcus. However,
        other more niche characters like Charlie and Voyager also still work.
      </p>
      <p>
        To note is that this Raid is… lowkey, kind of easy if you have the right
        units. During testing we found that we could hit S rank turn 10 with a
        team entirely made of i2l50 characters (Windsong / Avgust / Vila /
        Voyager) and while this could be a testament to Windsong more than
        anything, any decently built team that follows the fight’s mechanics is
        going to perform relatively well.
      </p>
      <div className="team-box">
        <h5 className="beast">REHAB FOR THE DEPRESSED ACTOR</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="windsong"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="37"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="voyager"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="ezra-theodore"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="vila"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="yenisei"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team oriented around the newly released Windsong, this team
            focuses on keeping her stacks low using Vila and 6 while prepping
            her burst window with various buffs and 37’s [Supplementary Formula]
            in the back pocket.
          </p>
          <ul>
            <li>
              Windsong is absolutely the core of the team here, Everything is
              geared towards allowing Windsong to prep as much “For
              Rehabilitation” Incantations as she can. Then, during the
              [Mapping] from her Ultimate, she unleashes your entire hand with
              37 in the back spinning the gacha wheel on her enemies.
            </li>
            <li>
              6 is here for multiple reasons: a defensive anchor, since Windsong
              is taking extra damage for being the [Special Guest]. Secondly,
              because he gets access to a somewhat consistent [Incantation
              Empowerment I] through his Eureka which allows him to help
              Windsong build her hand.
            </li>
            <li>
              Vila is pretty obvious here. Single target healing and buffing
              allows her to keep [Heightened Standards] low on stacks while her
              passive Mass healing and access to +Moxie/Eureka help her both
              keep the team healthy and smooth out other character’s cycles.
            </li>
            <li>
              37 is the most replaceable part of this team. Watching the gacha
              wheel spin several times per Ultimate cast is fun, but it’s not
              necessarily the optimal way to play it. Characters like Voyager
              and Ezra (especially Ezra) who offer further Crit support on top
              of their defensive options are greatly appreciated.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Yenisei</strong> - As a powerful defensive healer, the
              further you go into Endless mode, the more valuable she becomes.
              In addition, her access to upgrading her own cards through her
              [Flow] buff greatly aided in this fight specifically.
            </li>
          </ul>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">FUCK IT WE BӒLL</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="kaalaa-baunaa"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="marcus"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="melania"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="isolde"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="an-an-lee"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sub-carry">Carry/Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="ezra-theodore"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="vila"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="yenisei"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team surrounding Kaalaa Baunaa, partially because of the BNW
            exploit doing so well here, but also partially as a Marcus team.
            Both these Gals are extremely powerful, work well in the raid and
            can occupy a similar team. Just make sure your composition adheres
            to the minimum requirement needed to activate [Perusal] if you’re
            using Marcus.
          </p>
          <ul>
            <li>
              Kaalaa Baunaa is the main contender here. Her high Crit Rate
              allows her to exploit characters like Vila who give a lot of Crit
              Rate stats, which in turn lets her benefit off of Isolde and Ezra
              who, when combined, generate a LOT of Crit DMG in turn. In
              addition, Kaalaa Baunaa is one of the few characters who can
              comfortably utilize the BNW bug.
            </li>
            <li>
              Marcus is also a core option here, able to quickly upgrade her own
              cards through the consumption of Eureka which allows her to do
              consistently high damage. In addition, Vila allows Marcus to build
              more into Crit, allowing her to do the same thing as KB and put
              Isolde/Ezra to work. Naturally, if Vila is unavailable, there are
              other character options too.
            </li>
            <li>
              Isolde is here for her massive Crit DEF shred playing well with
              the rest of the set-up and her great Mass DMG over the course of
              the fight. Consistent [Power Burst] and semi-regular [Rousing
              Morale] near the mid-stages of the fight also greatly help both
              Marcus and KB.
            </li>
            <li>
              Ezra Theodore is here because [Discernment] and [Fragile] both
              slot really well into this team. Partly because it is very Crit
              focused, and partly because KB is able to consume multiple stacks
              at once under her Ultimate. In addition, his sizable shields help
              keep the team safe through the later stages of the fight.
            </li>
            <li>
              Vila is pretty obvious here. Single target healing and buffing
              allows her to keep [Heightened Standards] low on stacks while her
              passive Mass healing and access to +Moxie/Eureka help her both
              keep the team healthy and smooth out other character’s cycles. Her
              Crit buffs also smoothen out KB’s stats while putting Marcus in
              the realm of being a Crit Carry.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>An-An Lee</strong> - Works with both KB and Marcus. For
              the former because she has good buffs and an AP-positive
              playstyle, for the latter because if Vila isn’t there Marcus does
              better in raw DMG comps and AAL counts towards her Insight
              requirements.
            </li>
            <li>
              <strong>6</strong> - Is here as a generalist with access to a wide
              range of (de)buffs, a strong single-target Ultimate and
              [Incantation Empowerment I] while also counting towards Marcus’
              Insight requirements.
            </li>
            <li>
              <strong>Melania</strong> - Melania is practically made to function
              in Mane’s Bulletin; extended fights with a single-target are her
              specialty as she can comfortably stack [Fixed Plan] to ramp up her
              damage without worrying about killing her opponent too quickly.
            </li>
          </ul>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Echoes of Opera | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Echoes of Opera Raid!"
    game="reverse"
  />
);
